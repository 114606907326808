import { qsa, qs } from "../../resources/js/utils/dom";

window.addEventListener('load', () => {
  const games = qsa('.featured-games-item')

  const observer = new IntersectionObserver(function (entries) {
    if (entries[0].intersectionRatio <= 0) return

    entries.forEach(entry => qs('video', entry.target)?.play())
  })

  games?.forEach(game => observer.observe(game))
});
